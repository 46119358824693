// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const reportsApi = createApi({
	reducerPath: 'reportsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		getEmailReportCheckToken: build.query({
			query: ({ accessToken }) => `/api/email-report/checkToken/${accessToken}`
		}),
		getUserReport: build.mutation({
			query: (body: { accessToken; organizationId; userId }) => ({
				url: `/api/email-report/userReport`,
				method: 'POST',
				body
			})
		}),
		sendUserReport: build.mutation({
			query: () => ({
				url: `/api/email-report/manual/send`,
				method: 'POST'
			})
		})
	})
});

export const {
	useGetEmailReportCheckTokenQuery,
	useLazyGetEmailReportCheckTokenQuery,
	useGetUserReportMutation,
	useSendUserReportMutation
} = reportsApi;
