// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const auditAccessibilityApi = createApi({
	reducerPath: 'auditAccessibilityApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(false);
		}
	}),
	tagTypes: ['audit-accessibility'],
	endpoints: (build) => ({
		getAuditAccessibilityDetails: build.query({
			query: ({ guid }) => `/api/audit/accessibility/${guid}`,
			providesTags: ['audit-accessibility']
		}),
		setAuditAccessibility: build.mutation({
			query: (body: { email: string; language: string; webSiteName: string; pages: any }) => ({
				url: `/api/audit/accessibility`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useGetAuditAccessibilityDetailsQuery, useSetAuditAccessibilityMutation } = auditAccessibilityApi;
