// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ScopeType from 'components/legacy/models/types/scopteType';

// Externals
import { baseHeader } from '../base-header';

export interface Permission {
	key: string;
	name: string;
	description: string;
	scope: ScopeType; // Ce n'est pas un simple string, mais un objet
}
export interface Role {
	key: string;
	name: string;
	description: string;
	scope: ScopeType; // Ce n'est pas un simple string, mais un objet
	permissions: Permission[];
}

interface IResponse {
	data: any;
	errors: any;
}
interface PermissionsResponse extends IResponse {
	data: {
		permissions: Permission[];
	};
}
interface RolesResponse extends IResponse {
	data: {
		roles: Role[];
	};
}

interface hasPermissionResponse extends IResponse {
	data: { hasPermission: boolean };
}
export const adminApi = createApi({
	reducerPath: 'adminApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['admin-accounts', 'admin-permissions', 'admin-roles', 'admin-accessibility-audit'],
	endpoints: (build) => ({
		getAdmins: build.query({
			query: () => '/api/admins/getadmins'
		}),
		getAccountsAdmin: build.query({
			query: () => `/api/admin/accounts/getaccounts`,
			providesTags: ['admin-accounts']
		}),
		getPermissions: build.query<PermissionsResponse, void>({
			query: () => `/api/admin/permissions/getPermissions`,
			providesTags: ['admin-permissions']
		}),
		getRoles: build.query<RolesResponse, void>({
			query: () => `/api/admin/roles/getRoles`,
			providesTags: ['admin-roles']
		}),
		getListAuditAccessibilityAdmin: build.query({
			query: () => `/api/admin/accessibility-audits`,
			providesTags: ['admin-accessibility-audit']
		}),
		getDetailsAuditAccessibilityAdmin: build.query({
			query: ({ auditId }) => `/api/admin/accessibility-audits/${auditId}`,
			providesTags: ['admin-accessibility-audit']
		}),
		addRole: build.mutation({
			query: (body: {
				key: string;
				scope: string;
				permissions: { key: string; scope: string }[];
				name: string;
				description: string;
			}) => ({
				url: `/api/admin/roles/add`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['admin-roles']
		}),
		updateRole: build.mutation({
			query: (body: {
				key: string;
				scope: string;
				permissions: { key: string; scope: string }[];
				name: string;
				description: string;
			}) => ({
				url: `/api/admin/roles/update`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['admin-roles']
		}),
		deleteUserAccountAdmin: build.mutation({
			query: (body: { userId: string; reason: string }) => ({
				url: `/api/admin/accounts/deleteUserAccount`,
				method: 'DELETE',
				body
			}),
			invalidatesTags: ['admin-accounts']
		}),
		updateAdminUserRole: build.mutation({
			query: (body: { currentUserId: string; userId: string; roleKeys: string[] }) => ({
				url: `/api/admins/SetAdminRole`,
				method: 'POST',
				body
			})
		}),
		sendAdminReportAcessibility: build.mutation({
			query: (body: { auditId: string }) => ({
				url: `/api/admin/accessibility-audits/${body.auditId}/sendreport`,
				method: 'POST',
				body: ''
			})
		}),
		updateStatusAuditAccessibility: build.mutation({
			query: (body: { auditId: string }) => ({
				url: `/api/admin/accessibility-audits/${body.auditId}/status`,
				method: 'PATCH',
				body: ''
			}),
			invalidatesTags: ['admin-accessibility-audit']
		}),
		updateTechnologiesAuditAccessibility: build.mutation({
			query: (body: { auditId: any; technos: any }) => ({
				url: `/api/admin/accessibility-audits/${body.auditId}/technologies`,
				method: 'PATCH',
				body: body.technos
			}),
			invalidatesTags: ['admin-accessibility-audit']
		}),
		updateTestEnvAuditAccessibility: build.mutation({
			query: (body: { auditId: any; testEnv: any }) => ({
				url: `/api/admin/accessibility-audits/${body.auditId}/testenvironments`,
				method: 'PATCH',
				body: body.testEnv
			}),
			invalidatesTags: ['admin-accessibility-audit']
		}),
		updateToolsAuditAccessibility: build.mutation({
			query: (body: { auditId: any; tools: any }) => ({
				url: `/api/admin/accessibility-audits/${body.auditId}/tools`,
				method: 'PATCH',
				body: body.tools
			}),
			invalidatesTags: ['admin-accessibility-audit']
		}),
		updateAnswerAuditAccessibility: build.mutation({
			query: ({
				auditId,
				pageId,
				responseId,
				response
			}: {
				auditId: string;
				pageId: number;
				responseId: number;
				response: any;
			}) => ({
				url: `/api/admin/accessibility-audits/${auditId}/${pageId}/${responseId}`,
				method: 'PUT',
				body: { response }
			}),
			invalidatesTags: ['admin-accessibility-audit']
		}),
		getHasPermission: build.query<hasPermissionResponse, { permissionKeys: string[]; allMatch: boolean } | string[]>({
			query: (params) => {
				const isDirectArray = Array.isArray(params); // Vérifier si `params` est un tableau
				const permissionKeys = isDirectArray ? params : params.permissionKeys;
				const allMatch = isDirectArray ? false : params.allMatch ?? false;

				const queryParams = permissionKeys.map((key) => `permissionKeys=${encodeURIComponent(key)}`).join('&');
				return `/api/admins/hasPermission?${queryParams}&allMatch=${allMatch}`;
			}
		})
	})
});

export const {
	useGetAdminsQuery,
	useDeleteUserAccountAdminMutation,
	useGetAccountsAdminQuery,
	useAddRoleMutation,
	useUpdateRoleMutation,
	useUpdateAdminUserRoleMutation,
	useGetPermissionsQuery,
	useGetRolesQuery,
	useGetHasPermissionQuery,
	useGetListAuditAccessibilityAdminQuery,
	useGetDetailsAuditAccessibilityAdminQuery,
	useUpdateAnswerAuditAccessibilityMutation,
	useUpdateStatusAuditAccessibilityMutation,
	useUpdateTechnologiesAuditAccessibilityMutation,
	useUpdateToolsAuditAccessibilityMutation,
	useUpdateTestEnvAuditAccessibilityMutation,
	useSendAdminReportAcessibilityMutation
} = adminApi;
